$(function () {
    initNav();
    initSlideshow();
    initCarousel();
    initProductSlider();
    initEqualHeights();
    initTabs();
    initFaq();
    initCookiebox();
    initFavorite();
    initFooter();
    initBrandSlider();
    initProfSlider();
    initCompanySlider();
    initKousenSlider();
    initTopUspSlider();
    inithandleHeader();
    initReadMoreHome();
    initTogglePass();
    initClickLiveChat();
});

$(window).on('load', function() {
    initMatchheight();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        // initNav();
        initEqualHeights();
        initFooter();
    }, 100));
});

$.ajaxSetup({
    headers: {
        [csrf.header]: csrf.token
    }
});

function initSlideshow() {
    $('#slideshow').slick({
        autoplay: true,
        arrows: false,
        fade: true,
        dots: true
    });
}

function initTogglePass() {
    const passwordContainers = document.querySelectorAll('.password-container');

    passwordContainers.forEach(function(container) {
        const inputField = container.querySelector('input[type="password"]');
        const toggleButton = container.querySelector('.toggle-password');

        if (inputField && toggleButton) {
            toggleButton.addEventListener('click', function() {
                const type = inputField.getAttribute('type') === 'password' ? 'text' : 'password';
                inputField.setAttribute('type', type);
                toggleButton.classList.toggle('icon-eye');
                toggleButton.classList.toggle('icon-eye-off');
            });
        }
    });
}

function initReadMoreHome() {
    if(document.querySelector('#readMore')) {
        const readMore = document.querySelector('#readMore .btn');
        const content = document.querySelector('#readMore .more-text');

        if(window.innerWidth < 992) {
            readMore.addEventListener('click', function(e) {
                e.preventDefault();
                content.classList.toggle('active');
            });
        }
    }
}

function inithandleHeader() {
    if($(window).width() > 992) {
        $(window).scroll(function() {
            if ($(this).scrollTop() > 40) {
                $('header').addClass('fixed');
                $('body').addClass('fixed');
                if($('.user-btn').hasClass('active')){
                    $('header nav').addClass('overlay-effect');
                }
            } else {
                $('header').removeClass('fixed');
                $('body').removeClass('fixed');
            }
        });
    }
}

function initKousenSlider(){
    if ($('#kousenSlider').length > 0) {
        $('#kousenSlider').slick({
            mobileFirst: true,
            slidesToShow: 2,
            arrows: false,
            dots: true,
            appendDots: '#kousen .slider-nav',
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,

            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }
}

function initTopUspSlider(){
    if ($('#topUspSlider').length > 0) {
        $('#topUspSlider').slick({
            mobileFirst: true,
            slidesToShow: 1,
            arrows: false,
            dots: false,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,

            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1100,
                    settings: 'unslick'
                }
            ]
        });
    }
}

function initProfSlider(){
    $('#profSlider .bottom').slick({
        prevArrow: '#profSlider .nav .slick-prev',
        nextArrow: '#profSlider .nav .slick-next',
    });

    $('#profSlider .bottom').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('#profSlider .menu li a').removeClass('active');
        $('#profSlider .menu li').eq(nextSlide).find('a').addClass('active');

        $('#profSlider .content-slide').hide();
        $('#profSlider .content-slide').eq(nextSlide).show();
    });

    $('#profSlider .content-slide').hide();
    $('#profSlider .content-slide').first().show();

    $('#profSlider .menu li a').on('click', function(e){
        e.preventDefault();
        var slideIndex = $(this).parent().index();
        $('#profSlider .bottom').slick('slickGoTo', slideIndex);
    });
}

function initCompanySlider() {
    if ($('.companies #companySlider').length > 0) {
        $('.companies #companySlider').slick({
            mobileFirst: true,
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,

            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1439,
                    settings: {
                        slidesToShow: 6
                    }
                }
            ]
        });
    }
}

function initNav() {
    $('.menu-btn, header nav ul > li, .search-btn, .cart-btn, .user-btn').unbind('click');
    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');

    // Navigation
    if ($(window).width() < 992) {

        // Menu
        $('.menu-btn').on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $('header nav').slideDown(300, function () {
                    $('header nav ul li').each(function (i, el) {
                        if ($(el).children('ul').length) {
                            var height = $(el).outerHeight() + $(el).children('ul').outerHeight();
                            $(el).attr('data-height', height);
                        }
                    });
                });
            } else {
                closeNav($(this));
            }
        });

        $('header nav ul li a > span').on('click', function (e) {
            e.preventDefault();
            var $span = $(this);
            var $a = $span.parent();

            if ($a[0].hasAttribute('href')) {
                var href = $a.attr('href');
                if(href !== '#'){
                    window.location = href;
                }
            }
        });

        $('header nav ul li a').on('click', function (e) {
            e.preventDefault();
            var $a = $(this);
            var $li = $a.parent();
            if ($li.children('ul').length) {
                e.preventDefault();
                $li.toggleClass('open');

                if ($li.hasClass('open')) {
                    $li.parent('ul').addClass('sub-open');

                    // $('nav > .container > ul, li.open').css('height', $li.data('height'));

                    // console.log($li.data('height'));

                    // $('ul.sub-open').each(function (i, el) {
                    //     $(el).prev('a').addClass('hide');
                    // });
                } else {

                    $li.parent('ul').removeAttr('style');
                    $li.parent('ul').prev('a').removeClass('hide');
                    $li.parent('ul').removeClass('sub-open');
                    $li.removeAttr('style');

                    var $last_open_li = $li.closest('li.open');

                    console.log($last_open_li);

                    // $('nav > .container > ul, li.open').css('height', $last_open_li.data('height'));
                }
            }
        });

    } else {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
            $('header nav ul li').each(function (i, el) {
                let $el = $(el);
                $el.children('a').click(function(e){
                    if(!$el.hasClass('clicked') && $el.has('ul').length > 0) {
                        e.preventDefault();
                        $el.addClass('clicked');
                    }
                });
            });
        }

        $(document).mouseup(function (e) {
            var container = $('.user-btn, .user');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if(!$('.cart-popup').hasClass('active')) {
                    $('.user').hide();
                    $('.user-btn').removeClass('active');
                    $('.overlay').removeClass('active');
                    $('header nav').removeClass('overlay-effect');
                }
            }
        });
    }

    $('#searchform .search_input').on('focus', function(e) {
        $(this).closest('.search-wrap').addClass('active');
    });

    $('#searchform .search_input').on('blur', function(e) {
        $(this).closest('.search-wrap').removeClass('active');
    });

    // User
    $('.user-btn').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('header .user').fadeToggle(100);
        $('.overlay').toggleClass('active');
        if($(window).width() > 992) {
            if ($(window).scrollTop() > 40) {
                $('header nav').addClass('overlay-effect');
            } else {
                $('header nav').removeClass('overlay-effect');
            }
        }
    });
}

function closeNav(e) {
    e.removeClass('active');

    $('header nav').slideUp({
        duration: 300
    }).css({
        zIndex: 0
    });

    $('header nav ul').removeClass('sub-open').removeAttr('style');
    $('header nav ul li').removeClass('open').removeAttr('style');
}

function initCarousel() {
    $('#carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false
    });
}

function initProductSlider() {
    if ($('#home #products .product-slider').length > 0) {
        $('#home #products .product-slider').slick({
            mobileFirst: true,
            slidesToShow: 2,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToScroll: 2,
            prevArrow: '<i class="icon icon-chevron-left prev"></i>',
            nextArrow: '<i class="icon icon-chevron-right next"></i>',

            responsive: [
                {
                    breakpoint: 552,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1439,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights').each(function () {
            var parent = $(this),
                items = parent.data('equals').replace(/ /g, '').split(','),
                height;

            items.forEach(function (item) {
                height = 0;

                parent.find(item).each(function () {
                    if ($(this).height() > height) {
                        height = $(this).height();
                    }
                });

                if (parent.hasClass('no-mobile') && $(window).width() < 767) {
                    // Do nothing...
                } else {
                    parent.find(item).css({
                        minHeight: height
                    });
                }
            });
        });
    }
}

function initTabs() {
    if ($('.tabs').length > 0) {
        $('.tabs nav ul .tab').each(function () {
            $(this).click(function () {
                $('.tabs nav ul .tab.active').removeClass('active');
                $(this).addClass('active');

                if ($(this).hasClass('active')) {
                    $('.tabs .tab-contents .tab-content.active').removeClass('active');
                    $('#' + $(this).data('tab')).addClass('active');
                }
            });
        });
    }
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}

function initMatchheight() {
    $('.matchheight').matchHeight();
}

function initCookiebox() {
    if ($('#cookiebox').length && document.cookie.indexOf('cookiebox=') === -1) {
        setTimeout(function () {
            $('#cookiebox').hide().fadeIn('fast').css({
                bottom: 30
            });
        }, 500);

        $('#cookiebox .accept').click(function () {
            setCookie('cookiebox', 1, 365);
            $('#cookiebox').fadeOut().css({
                bottom: -230
            });
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

function initFavorite() {
    $('.product .favorite').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const id = $button.data('product');

        const data = {
            id
        };

        $.ajax({
            type: 'POST',
            url: $button.attr('href'),
            data,
            dataType: 'json',
            success: function (favorites) {
                if (typeof favorites[id] === 'undefined') {
                    if($('#products').hasClass('favorites')){
                        $button.parents('.product').fadeOut();

                        console.log($('.product').length);

                        if(favorites.length == 0) {
                            $('<div class="alert alert-info" style="margin-top: 30px;">Er zijn momenteel geen favorieten.</div>').appendTo($('.page-content'));
                        }
                    } else {
                        $button.removeClass('active')
                    }
                } else {
                    $button.addClass('active')
                }
            }
        });
    });
}

function showPopmessage(text) {
    $('.popmessage').text(text).addClass('active');

    setTimeout(function(){
        $('.popmessage').removeClass('active');
    }, 3000);
}

function initFooter() {
    if ($(window).width() < 768) {
        $('footer nav > ul > li').each(function() {
            $(this).find('h4').unbind().click(function() {
                $(this).toggleClass('active');
                $(this).parent().find('ul').slideToggle('fast');
            });
        });
    }
}

function initBrandSlider() {
    if ($('#brands').length > 0) {
        $('.brand-slider').slick({
            variableWidth: true,
            mobileFirst: true,
            autoplay: true,
            prevArrow: '<i class="icon icon-chevron-left prev"></i>',
            nextArrow: '<i class="icon icon-chevron-right next"></i>'
        });
    }
}

function initClickLiveChat() {
    const openLiveChat = document.querySelector('#openLiveChat');

    if (openLiveChat) {
        openLiveChat.addEventListener('click', function(e) {
            e.preventDefault();

            const chatra = document.querySelector('#chatra');

            if (chatra) {
                Chatra('openChat', true);
            }
        });
    }
}