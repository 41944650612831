$(function () {
    if ($('#filter').length > 0) {
        initResponsive();
        initParts();
        initValues();
        initOrder();
    }
});

let timeout = null;

function initResponsive() {
    if ($(window).width() < 768) {
        $('#filter-toggle').click(function () {
            $('#filter').toggleClass('active');

            if ($('#filter').hasClass('active')) {
                $('#filter').slideDown('fast');
            } else {
                $('#filter').slideUp('fast');
            }
        });
    } else if ($(window).width() < 980) {
        $('#filter-toggle').click(function () {
            $(this).toggleClass('active');
            $('.filter').toggleClass('active');
        });
    }
}

function initParts() {
    $('#filter .part').each(function () {
        if ($(this).hasClass('active')) {
            $(this).find('.content').show();
        } else {
            $(this).find('.content').slideUp('fast');
        }

        if ($(this).find('.content label').length > 5) {
            var $part = $(this);

            $part.addClass('more')
                .append('<a href="#" class="show-more">Toon <span>meer</span> <i class="more icon icon-chevron-down"></i></a>');

            $part.find('.content').css({
                height: 31 * 5 - 5
            });

            $part.find('.show-more').click(function (e) {
                e.preventDefault();

                $(this).toggleClass('active');

                if ($(this).hasClass('active')) {
                    $(this).find('span').text('minder');
                    $(this).find('.icon').removeClass('icon-chevron-down').addClass('icon-chevron-up');
                    $part.find('.content').css({
                        height: 31 * $part.find('.content label').length - 5
                    });
                } else {
                    $(this).find('span').text('meer');
                    $(this).find('.icon').removeClass('icon-chevron-up').addClass('icon-chevron-down');
                    $part.find('.content').css({
                        height: 31 * 5 - 5
                    });
                }
            });
        }

        $(this).find('.title').on('click', function () {
            const $this = $(this).parents('.part');

            $this.toggleClass('active');

            if ($this.hasClass('active')) {
                $this.find('.content').slideDown('fast');
            } else {
                $this.find('.content').slideUp('fast');
            }
        });
    });

    $('#removeFilters').on('click', function (e) {
        e.preventDefault();
        $('#frmFilter input').attr('checked', false);
        submitFilters();
    });
}

function initValues() {
    $('#frmFilter input').on('click', function (e) {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submitFilters();
        }, 1000);
    });

    // Preselect existing
    let parts = $('#filter').data('values');
    Object.keys(parts).forEach(function (key) {
        parts[key].forEach(function (value) {
            $('[name="' + key + '[]"][value="' + value + '"]').attr('checked', true);
        })
    });
}

function initOrder() {
    $('#productOrder').on('change', function (e) {
        e.preventDefault();
        submitFilters();
    });
}

function submitFilters() {

    if ($('#filter-toggle').is(':visible')) {
        return;
    }

    const data = $('#frmFilter').serialize();
    const dataObj = [];
    dataObj.push(data);

    const zoekterm = $('#zoekterm').val();
    if (zoekterm !== '' && zoekterm !== undefined) {
        dataObj.push('zoekterm=' + zoekterm);
    }
    dataObj.push('order=' + $('#productOrder').val());

    window.location.href = window.location.href.split('?')[0] + '?' + decodeURIComponent(dataObj.join('&'));
}