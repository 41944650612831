$(function () {
    if ($('#club #addressForm').length > 0) {
        $('input[name="postal_code"], input[name="house_number"]').on('keyup, blur', function () {
            checkPostalZip();
        });
    }
});

// Uitlezen en bepalen van adresgegevens
function checkPostalZip() {
    console.log('check');

    const country = $('select[name="country"]').val();
    const postalCode = $('input[name="postal_code"]').val();
    const houseNumber = $('input[name="house_number"]').val();
    if (country === 'NL') {
        if (postalCode !== '' && houseNumber !== '') {
            delayGetAdress(function () {
                getAdressByPostalZip(postalCode, houseNumber);
            }, 400);
        }
    }
}

// Aanroepen van de postcode api
function getAdressByPostalZip(postalCode, houseNumber) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (typeof (result.exception) === 'undefined') {
                handleAdressRequestData(result);
            }
        }
    });
}

// Timeout
var delayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function handleAdressRequestData(address) {
    $('input[name="street"]').val(address.street);
    $('input[name="city"]').val(address.city);
}

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
}