/**
 * Update order.
 *
 * @param order
 */
let updateRequest;

function updateOrder() {
    if (updateRequest) {
        updateRequest.abort();
    }

    const $order = $('#order');
    const $form = $('#checkoutForm');
    const $passwordWrapper = $('#passwordWrapper');
    const $shippingOption = $form.find('input[name="shipping_option"]:checked');
    const $paymentMethod = $form.find('input[name="payment_method"]:checked');
    const $olfitClub = $form.find('input[name="olfit_club"]');
    const $paymentMethodOnPickup = $form.find('input[name="payment_method"][value="on-pickup"]');

    const data = {};
    switch ($shippingOption.val()) {
        case 'billing_address':
            data.country = $form.find('#billing_country').val();
            break;
        case 'shipping_address':
            data.country = $form.find('#shipping_country').val();
            break;
    }

    switch ($shippingOption.val()) {
        case 'pickup':
            $paymentMethodOnPickup.prop('disabled', false);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .show();
            break;
        default:
            if ($paymentMethod.val() === 'on-pickup') {
                $('input[name="payment_method"]:first').prop('checked', true);
            }
            $paymentMethodOnPickup.prop('disabled', true);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .hide();
            break;
    }

    if ($olfitClub.prop('checked') === true) {
        $passwordWrapper.find('input').attr('disabled', false);
        $passwordWrapper.show();
        $('input[name="newsletter"]').attr('checked', true);
    } else {
        $passwordWrapper.hide();
        $passwordWrapper.find('input').attr('disabled', true);
        $('input[name="newsletter"]').attr('checked', false);
    }

    data.shipping_option = $shippingOption.val();
    data.payment_method = $paymentMethod.val();
    data.olfit_club = $olfitClub.prop('checked') === true ? 'true' : 'false';

    updateRequest = $.ajax({
        type: 'GET',
        data,
        url: url.order.data,
        dataType: 'json',
        success: function (order) {

            // HAPPY IDIOTS REMARKETING
            const ids = [];
            $(order.rows).each(function (i, el) {
                ids.push(el.id);
            });

            dataLayer.push({
                'event': 'remarketing',
                'ecomm_pagetype': 'Checkout',
                'ecomm_prodid': ids,
                'ecomm_totalvalue': order.total_plus_shipping_transaction
            });
            //--

            if ($order.length) {
                if (order.rows.length) {
                    const $paymentMethodInput = $('[name="payment_method"]');
                    console.log(order.total_plus_shipping_handling_amount);
                    if (
                        ($paymentMethodInput.length > 0 && order.total_plus_shipping_handling_amount <= 0)
                        || ($paymentMethodInput.length === 0 && order.total_plus_shipping_handling_amount > 0)
                    ) {
                        location.reload();
                    }

                    const containerTemplate = $('script[data-template="order-container"]').text();

                    const $tax = $('<div/>', {class: 'row tax'});
                    order.tax_data.forEach(function (entry) {
                        $tax.append($('<div/>', {class: 'col-xs-8'}).html('<strong>Btw totaal (' + entry.rate + '%)</strong>')) // (' + entry.rate + '%)
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text(entry.calculated));
                    });

                    const $discount = $('<div/>');
                    if (null !== order.discount_coupon) {
                        const $discountCouponDiscount = $('<div/>', {class: 'row discount'});

                        if (order.has_discount_coupon_discount) {
                            $discountCouponDiscount.append($('<div/>', {class: 'col-xs-8'}).text('Kortingscode: ' + order.discount_coupon.code.toUpperCase() + ' (' + order.discount_coupon.description + ')'))
                                .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.discount_coupon_discount));
                        }

                        $discount.append($discountCouponDiscount);
                    }

                    if (order.has_product_tier_discount) {
                        const $productTierDiscount = $('<div/>', {class: 'row discount'});

                        $productTierDiscount.append($('<div/>', {class: 'col-xs-8'}).text('Actie korting'))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.product_tier_discount));

                        $discount.append($productTierDiscount);
                    }

                    if (order.has_club_discount) {
                        const $clubDiscount = $('<div/>', {class: 'row discount'});

                        $clubDiscount.append($('<div/>', {class: 'col-xs-8'}).text(order.club_level))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.club_discount));

                        $discount.append($clubDiscount);
                    }

                    if (order.has_combination_discount) {
                        const $combinationDiscount = $('<div/>', {class: 'row discount'});

                        $combinationDiscount.append($('<div/>', {class: 'col-xs-8'}).text('Combinatie korting'))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.combination_discount));

                        $discount.append($combinationDiscount);
                    }

                    const $budget = $('<div/>');
                    if (order.has_used_budget) {
                        const $usedBudget = $('<div/>', {class: 'row discount'});

                        $usedBudget.append($('<div/>', {class: 'col-xs-8'}).text('Verbruikt budget'))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.used_budget));

                        $budget.append($usedBudget);
                    }

                    const $container = $(containerTemplate.replace(/{{ id }}/g, order.id)
                        .replace(/{{ count }}/g, order.count)
                        .replace(/{{ subtotal }}/g, order.total_without_discount)
                        .replace(/{{ discount }}/g, $discount[0].outerHTML)
                        .replace(/{{ budget }}/g, $budget[0].outerHTML)
                        .replace(/{{ tax }}/g, $tax[0].outerHTML)
                        .replace(/{{ shipping_fee }}/g, order.shipping_fee)
                        .replace(/{{ handling_fee }}/g, order.handling_fee)
                        .replace(/{{ total }}/g, order.total_plus_shipping_handling));

                    $order.empty().append($container);

                    if (order.handling_fee == '€ 0,00') {
                        $('.row.handling').addClass('hidden');
                    } else {
                        $('.row.handling').removeClass('hidden');
                    }

                    const $orderRows = $('.order-rows');
                    $orderRows.empty();
                    const rowTemplate = $('script[data-template="order-row"]').text();
                    $.each(order.rows, function (index, row) {
                        let options = '';
                        let fields = '';
                        if (row.variant.options.length) {
                            const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                            options += '<div class="options">';
                            $.each(row.variant.options, function (index, option) {
                                options += '<span class="option">' + option.name + ' ' + option.value.name + '</span>';
                                fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                                    .replace(/{{ value }}/g, option.value.id);
                            });
                            options += '</div>';
                        }

                        let label = '';
                        if (row.variant.product.label !== null && row.variant.product.label.key === 'pre-order') {
                            label += '<small style="margin-left: 10px;">(Pre-order)</small>';
                        }

                        let discount = 'Geen';
                        if (row.discount !== '€ 0,00') {
                            let tooltipText = '';
                            $.each(row.discounts, function (index, entry) {
                                tooltipText += (index > 0 ? ', ' : '') + (entry.quantity + ' x ' + entry.name);
                            });

                            discount = '- ' + row.discount + (tooltipText !== ''
                                ? '<i class="icon icon-info" style="padding-left: 10px;" data-toggle="tooltip" data-placement="bottom" title="' + tooltipText + '"></i>'
                                : '');
                        }

                        const $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                            .replace(/{{ variant.product.image }}/g, (row.variant.product.image ? row.variant.product.image : '/img/nophoto.png'))
                            .replace(/{{ variant.product.name }}/g, row.variant.product.name)
                            .replace(/{{ variant.options }}/g, options)
                            .replace(/{{ variant.sku }}/g, row.variant.sku)
                            .replace(/{{ label }}/g, label)
                            .replace(/{{ discount }}/g, discount)
                            .replace(/{{ variant.price }}/g, row.variant.price)
                            .replace(/{{ quantity }}/g, row.quantity + 'st')
                            .replace(/{{ fields }}/g, fields)
                            .replace(/{{ subtotal }}/g, row.subtotal)
                            .replace(/{{ url.product.show }}/g, url.product.show.replace(/@slug/g, row.variant.product.slug).replace(/@id/g, row.variant.product.id)));

                        $orderRows.append($row);
                    });

                    $('[data-toggle="tooltip"]').unbind().tooltip();

                    $orderRows.trigger('order.loaded');
                }

                initDiscount(order);
            }
        }
    });
}

function showAndEnable($element) {
    $element.show();
    $element.find('radio, input, select').prop('disabled', false);
}

function hideAndDisable($element) {
    $element.hide();
    $element.find('radio, input, select').prop('disabled', true);

}

function updateBillingAddress() {
    const $existingBillingAddress = $('#existingBillingAddress');
    const $newBillingAddress = $('#newBillingAddress');
    const value = $('input[name="billing_address_option"]:checked').val();
    switch (value) {
        case 'existing':
            hideAndDisable($newBillingAddress);
            showAndEnable($existingBillingAddress);
            break;
        case 'new':
            hideAndDisable($existingBillingAddress);
            showAndEnable($newBillingAddress);
            break;
    }
}

function updateShippingAddress() {
    const $existingShippingAddress = $('#existingShippingAddress');
    const $newShippingAddress = $('#newShippingAddress');
    const value = $('input[name="shipping_address_option"]:checked').val();
    switch (value) {
        case 'existing':
            hideAndDisable($newShippingAddress);
            showAndEnable($existingShippingAddress);
            break;
        case 'new':
            hideAndDisable($existingShippingAddress);
            showAndEnable($newShippingAddress);
            break;
    }
}

// Uitlezen en bepalen van adresgegevens
function checkOrderPostalZip(type) {
    const country = $('select[name="' + type + '_country"]').val();
    const postalCode = $('input[name="' + type + '_postal_code"]').val();
    const houseNumber = $('input[name="' + type + '_house_number"]').val();
    if (country === 'NL') {
        if (postalCode !== '' && houseNumber !== '') {
            orderDelayGetAdress(function () {
                getOrderAdressByPostalZip(postalCode, houseNumber, type);
            }, 400);
        }
    }
}

// Aanroepen van de postcode api
function getOrderAdressByPostalZip(postalCode, houseNumber, type) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (typeof (result.exception) === 'undefined') {
                handleOrderAdressRequestData(result, type);
            }
        }
    });
}

// Timeout
var orderDelayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function handleOrderAdressRequestData(adress, type) {
    $('input[name="' + type + '_street"]').val(adress.street);
    $('input[name="' + type + '_city"]').val(adress.city);

    $('input[name="' + type + '_street"], input[name="' + type + '_city"]').closest('.form-group').addClass('active');
}

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
}

function initDiscount(order) {
    const $discountCoupon = $('.discount-coupon');
    $discountCoupon.empty();

    if (null === order.discount_coupon) {
        const $discountCouponFormGroup = $('<div/>', {class: 'form-group'})
            .append($('<input/>', {
                type: 'text',
                name: 'code',
                class: 'form-control required',
                placeholder: 'Kortingscode'
            }))
            .append($('<button/>', {
                type: 'button',
                class: 'btn'
            }).attr('data-action', 'add-discount-coupon')
                .text('Toevoegen'))
            .append($('<b/>', {class: 'message'}));

        const $discountCouponForm = $('<form>', {id: 'addDiscountCouponForm'}).append($discountCouponFormGroup);

        $discountCoupon.html($discountCouponForm);

        $('[data-action="add-discount-coupon"]').off().on('click', function (e) {
            e.preventDefault();

            const $container = $('.discount-coupon');
            const $input = $container.find('input[name="code"]');
            const $message = $container.find('.message');
            const code = $input.val();

            $message.removeClass('text-danger')
                .addClass('text-success')
                .text('De kortingscode wordt toegepast, een ogenblik geduld...');

            $.ajax({
                type: 'POST',
                url: url.cart.discountCoupon,
                data: {
                    code
                },
                dataType: 'json',
                success: function (cart) {
                    updateOrder();
                },
                error: function (e) {
                    const message = e.responseJSON && 'message' in e.responseJSON ? e.responseJSON.message : 'Er is iets misgegaan.';

                    $container.addClass('has-error');

                    $message.removeClass('text-success')
                        .addClass('text-danger')
                        .text(message);
                }
            });
        });

        $('#addDiscountCouponForm').off().on('submit', function (e) {
            e.preventDefault();

            $('[data-action="add-discount-coupon"]').trigger('click');
        });
    } else {
        $discountCoupon.append($('<div/>', {class: 'row'})
            .append($('<div/>', {class: 'col-xs-8'}).html('<span class="code"><b>' + order.discount_coupon.code.toUpperCase() + '</b> toegepast</span>'))
            .append($('<div/>', {class: 'col-xs-4'}).append($('<a/>', {class: 'remove'}).attr('data-action', 'remove-discount-coupon')
                .html('&times;'))));

        $('[data-action="remove-discount-coupon"]').off().on('click', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'DELETE',
                url: url.cart.discountCoupon,
                dataType: 'json',
                success: function (cart) {
                    updateOrder();
                }
            });
        });
    }
}

$(document).ready(function () {

    var billingPrefix = $('#billing_prefix, #billing_first_name, #billing_last_name');

    billingPrefix.bind('input paste', function (e) {
        var node = $(this);
        node.val(node.val().replace(/[^a-zA-Z ]/g, ''));
    });

    if ($('#order').length) {
        $('input[name="payment_method"]:first').prop('checked', true);

        $('#billing_country, #shipping_country, input[name="shipping_option"], input[name="payment_method"]').on('change', function () {
            updateOrder();
        });

        $('input[name="billing_address_option"]').on('change', function () {
            updateBillingAddress();
        });

        $('input[name="shipping_address_option"]').on('change', function () {
            updateShippingAddress();
        });

        $('input[name="olfit_club"]').on('change', function () {
            updateOrder();
        });

        $('[name="existing_billing_address"]').on('change', function () {
            const $option = $('[name="existing_billing_address"] option:selected');
            let address = $option.attr('data-address');
            const isDefaultAddress = $option.attr('data-default') === 'true';
            address = address.replace(/,/g, '<br>');
            const $address = $('#existingBillingAddress').find('.address');
            $address.html(address);
            if (isDefaultAddress) {
                $address.append($('<br>')).append($('<span/>', {class: 'label label-info'}).text('Standaard factuuradres'));
            }
        });
        $('[name="existing_billing_address"]').trigger('change');

        $('[name="existing_shipping_address"]').on('change', function () {
            const $option = $('[name="existing_shipping_address"] option:selected');
            let address = $option.attr('data-address');
            const isDefaultAddress = $option.attr('data-default') === 'true';
            address = address.replace(/,/g, '<br>');
            const $address = $('#existingShippingAddress').find('.address');
            $address.html(address);
            if (isDefaultAddress) {
                $address.append($('<br>')).append($('<span/>', {class: 'label label-info'}).text('Standaard afleveradres'));
            }
        });
        $('[name="existing_shipping_address"]').trigger('change');

        $('#checkoutButton').on('click', function () {
            const $button = $(this);

            $button.attr('disabled', true);
            $('#checkoutForm').submit();
        });

        // Factuur / standaard afleveradres
        if ($('select[name="billing_country"]').length > 0
            && $('input[name="billing_postal_code"]').length > 0
            && $('input[name="billing_house_number"]').length > 0) {
            $('select[name="billing_country"]').on('change', function () {
                checkOrderPostalZip('billing');
            });
            $('input[name="billing_postal_code"], input[name="billing_house_number"]').on('keyup, blur', function () {
                checkOrderPostalZip('billing');
            });
        }
        checkOrderPostalZip('billing');

        // Afleveadres
        if ($('select[name="shipping_country"]').length > 0
            && $('input[name="shipping_postal_code"]').length > 0
            && $('input[name="shipping_house_number"]').length > 0) {
            $('select[name="shipping_country"]').on('change', function () {
                checkOrderPostalZip('shipping');
            });
            $('input[name="shipping_postal_code"], input[name="shipping_house_number"]').on('keyup, blur', function () {
                checkOrderPostalZip('shipping');
            });
        }
        checkOrderPostalZip('shipping');

        updateBillingAddress();
        updateShippingAddress();
        updateOrder();

        const $checkout = $('#checkout');
        const $shippingAddress = $('#shippingAddress');

        $checkout.on('update', function () {
            const $option = $('input[name="shipping_option"]:checked');
            switch ($option.val()) {
                case 'shipping_address':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $shippingAddress.show();
                    break;
                case 'billing_address':
                case 'pickup':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $shippingAddress.hide();
                    break;
            }
        });

        $('input[name="shipping_option"]').on('change', function () {
            $checkout.trigger('update');
        });

        $checkout.trigger('update');
    }
});